import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import permission from "./Permission";

const routes = [
  // {
  //   path: "/test", // 0.测试功能
  //   name: "/test",
  //   component: () => import("../views/TestPage.vue"),
  // },
  {
    path: "/404",
    component: () => import("../views/Error/NoFound.vue"),
    // hidden: true,
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
    // hidden: true,
  },
  {
    path: "/401",
    name: "/401",
    component: () => import("../views/Error/NoAuthority.vue"),
    // hidden: true,
  },
  {
    path: "/invitation-check/:p", // 1.空白页|企业邀请到达页
    name: "/invitation-check",
    component: () => import("../views/Error/InvitationCheck.vue"),
  },
  {
    path: "/",
    redirect: "/home/index",
  },
  {
    path: "/home", // 1.主页
    redirect: "/home/index",
  },
  {
    path: "/login/:orgName/:wxCard", // 0.登录
    name: "/login",
    component: () => import("../views/Login/Login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "index", // 1.主页
        name: "/home/index",
        component: () => import("../views/index/Index.vue"),
      },
      {
        path: "index/inviter/:inviterCode", // 1.主页|个人邀请到达页
        name: "/home/index/inviter",
        component: () => import("../views/index/Index.vue"),
      },
      {
        path: "Encyclopedia/:primaryInvestType", // 2.机构
        name: "/home/Encyclopedia",
        component: () => import("../views/Encyclopedia/Encyclopedia.vue"),
      },
      {
        path: "Talent/:type", // 3.个人
        name: "/home/Talent",
        component: () => import("../views/Talent/Talent.vue"),
        meta: {
          permission: ["person", "attach", "ins_card"],
        },
      },
      {
        path: "Talent-deta/:createUserId", // 3.1 个人百科
        name: "/home/Talent-deta",
        component: () => import("../views/Talent/TalentDeta.vue"),
        meta: {
          permission: ["person", "attach", "ins_card"],
        },
      },
      {
        path: "Equity", // 4.私募工具
        name: "/home/Equity",
        component: () => import("../views/Equity/Equity.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        path: "Markets/:id", // 5.市场
        name: "/home/Markets/index",
        component: () => import("../views/Markets/Markets.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        path: "Markets/BuyStep-one/:id", // 5.1 市场.购买步骤1
        name: "/home/Markets/BuyStep-one",
        component: () => import("../views/Markets/BuyStep1.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        path: "Markets/BuyStep-two/:id", // 5.1 市场.购买步骤2
        name: "/home/Markets/BuyStep-two",
        component: () => import("../views/Markets/BuyStep2.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        path: "Markets/BuyStep-three/:id", // 5.1 市场.购买步骤3
        name: "/home/Markets/BuyStep-three",
        component: () => import("../views/Markets/BuyStep3.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        path: "SaleStore", // 6.直营店
        name: "/home/SaleStore",
        component: () => import("../views/SaleStore/SaleStore.vue"),
      },
      {
        path: "Roadshow", // 7.路演
        name: "/home/Roadshow",
        component: () => import("../views/Roadshow/Roadshow.vue"),
      },
      {
        path: "Awards/index", // 8.奖项首页
        name: "/home/Awards/index",
        component: () => import("../views/Awards/Index.vue"),
        meta: {
          title: "奖项",
          permission: ["person", "ins"],
        },
      },
      {
        path: "Awards", // 8.1 奖项
        name: "/home/Awards",
        component: () => import("../views/Awards/Awards.vue"),
        meta: {
          title: "奖项",
          permission: ["person", "ins"],
        },
      },
      {
        path: "Awards/securities", // 8.2 奖项-证券类排名
        name: "/home/Awards/securities",
        component: () => import("../views/Awards/Securities/Securities.vue"),
        meta: {
          title: "奖项-证券类排名",
          permission: ["person", "ins"],
        },
      },
      {
        path: "Awards/non-securities", // 8.3 非证券类排名
        name: "/home/Awards/non-securities",
        component: () =>
          import("../views/Awards/NonSecurities/NonSecurities.vue"),
        meta: {
          title: "奖项-非证券类排名",
          permission: ["person", "ins"],
        },
      },
      {
        path: "Awards/awards-list", // 8.7 奖项-颁奖名单
        name: "/home/Awards/awards-list",
        component: () => import("../views/Awards/AwardList.vue"),
        meta: {
          title: "奖项-颁奖名单",
          permission: ["person", "ins"],
        },
      },
      {
        path: "GlobalSearch/:goodsName", // 9.全局搜索
        name: "/home/GlobalSearch",
        component: () => import("../views/GlobalSearch/GlobalSearch.vue"),
        meta: {
          title: "搜索",
          permission: ["person", "ins"],
        },
      },
      {
        path: "Manager", // 私募基金管理人
        name: "/home/Manager",
        component: () => import("../views/Manager/Manager.vue"),
      },
      {
        path: "Products", // 基金产品
        name: "/home/Products",
        component: () => import("../views/Products/Products.vue"),
      },
    ],
  },

  {
    // 私募工具
    path: "/homes",
    name: "homes",
    component: () => import("../views/HomesView"),
    meta: {
      title: "私募工具",
      permission: ["person", "ins"],
    },
    children: [
      {
        path: "DataCenter", // 数据中心
        name: "/homes/DataCenter",
        component: () =>
          import("../views/PrivateEquityTools/DataCenter/DataCenter.vue"),
      },
      {
        path: "FileDownload", // 文件下载
        name: "/homes/FileDownload",
        component: () =>
          import("../views/PrivateEquityTools/FileDownload/FileDownload"),
      },
      {
        path: "Question", // 私募问答
        name: "/homes/Question",
        component: () =>
          import("../views/PrivateEquityTools/Question/Question"),
      },
      {
        path: "Calendar", // 日历
        name: "/homes/Calendar",
        component: () =>
          import("../views/PrivateEquityTools/Calendar/Calendar.vue"),
      },
      {
        path: "Calendar-old", // 日历 | 旧
        name: "/homes/Calendar-old",
        component: () =>
          import("../views/PrivateEquityTools/Calendar/CalendarOld.vue"),
      },
      {
        path: "Course", // 网络课程
        name: "/homes/Course",
        component: () => import("../views/PrivateEquityTools/Course/Course"),
      },
      {
        path: "Handbook", // 指南手册
        name: "/homes/Handbook",
        component: () =>
          import("../views/PrivateEquityTools/Handbook/Handbook"),
      },
      {
        path: "FundDictionary", // 基金词典
        name: "/homes/FundDictionary",
        component: () =>
          import(
            "../views/PrivateEquityTools/FundDictionary/FundDictionary.vue"
          ),
      },
      {
        path: "SuperToolbox", // 超级工具箱
        name: "/homes/SuperToolbox",
        component: () =>
          import("../views/PrivateEquityTools/SuperToolbox/SuperToolbox.vue"),
      },
      {
        path: "/homes/SuperToolbox/Calculator/Earnings", // 基金收益
        component: () =>
          import(
            "../views/PrivateEquityTools/SuperToolbox/Calculator/Earnings"
          ),
      },
      {
        path: "/homes/SuperToolbox/Calculator/HoldingPeriod", // 基金持有其计算器
        component: () =>
          import(
            "../views/PrivateEquityTools/SuperToolbox/Calculator/HoldingPeriod"
          ),
      },
      {
        path: "/homes/SuperToolbox/Calculator/SubscriptionQuota", // 基金认购份额计算器
        component: () =>
          import(
            "../views/PrivateEquityTools/SuperToolbox/Calculator/SubscriptionQuota"
          ),
      },
      {
        path: "/homes/SuperToolbox/Calculator/ShareSubscription", // 基金份额申购计算器
        component: () =>
          import(
            "../views/PrivateEquityTools/SuperToolbox/Calculator/ShareSubscription"
          ),
      },
      {
        path: "/homes/SuperToolbox/Calculator/Redemption", //基金赎回计算器
        component: () =>
          import(
            "../views/PrivateEquityTools/SuperToolbox/Calculator/Redemption"
          ),
      },
      {
        path: "/homes/SuperToolbox/Calculator/Loss", // 基金投资损益计算器
        component: () =>
          import("../views/PrivateEquityTools/SuperToolbox/Calculator/Loss"),
      },
      {
        path: "/homes/SuperToolbox/Calculator/Yield", // 投资收益率计算器
        component: () =>
          import("../views/PrivateEquityTools/SuperToolbox/Calculator/Yield"),
      },
      {
        path: "/homes/SuperToolbox/Calculator/Interest", // 投资复利计算器
        component: () =>
          import(
            "../views/PrivateEquityTools/SuperToolbox/Calculator/Interest"
          ),
      },
      {
        path: "/homes/SuperToolbox/Calculator/BondYield", // 债券收益计算器
        component: () =>
          import(
            "../views/PrivateEquityTools/SuperToolbox/Calculator/BondYield"
          ),
      },
    ],
  },
  {
    // 用户中心2首页
    path: "/userHome",
    name: "/userHome",
    component: () => import("../views/UserHome/UserHomeView.vue"),
    meta: {
      isLogin: true,
    },
    children: [
      {
        // 1.用户中心首页
        path: "index",
        name: "/userHome/index",
        component: () =>
          import("../views/UserHome/UserHomeIndex/UserHomeIndexNew.vue"),
        meta: {
          title: "用户中心-首页",
          permission: ["person", "ins"],
        },
      },
      {
        // 2.用户设置 - 首页
        path: "basic-sttings",
        name: "/userHome/basic-sttings",
        component: () =>
          import("../views/UserHome/BasicSettings/BasicSettings.vue"),
        meta: {
          title: "用户中心-设置",
          permission: ["person", "ins"],
        },
      },
      {
        // 2.1 用户设置 - 核心信息
        path: "basic-sttings/core-information",
        name: "/userHome/basic-sttings/core-information",
        component: () =>
          import(
            "../views/UserHome/BasicSettings/DataManagement/CoreInformation.vue"
          ),
        meta: {
          title: "用户中心-设置-核心信息",
          permission: ["person", "ins"],
        },
      },
      {
        // 2.2 用户设置 - 补充信息
        path: "basic-sttings/supplementary-information",
        name: "/userHome/basic-sttings/supplementary-information",
        component: () =>
          import(
            "../views/UserHome/BasicSettings/DataManagement/SupplementaryInformation.vue"
          ),
        meta: {
          title: "用户中心-设置-补充信息",
          permission: ["person", "ins"],
        },
      },
      {
        // 2.3 用户设置 - 其他信息
        path: "basic-sttings/other-information",
        name: "/userHome/basic-sttings/other-information",
        component: () =>
          import(
            "../views/UserHome/BasicSettings/DataManagement/OtherInformation.vue"
          ),
        meta: {
          title: "用户中心-设置-其他信息",
          permission: ["person", "ins"],
        },
      },
      {
        // 2.4 用户设置 - 核心信息 | 个人
        path: "basic-sttings/person/core-information",
        name: "/userHome/basic-sttings/person/core-information",
        component: () =>
          import(
            "../views/UserHome/BasicSettings/PersonInformation/CoreInformationPerson.vue"
          ),
        meta: {
          title: "用户中心-设置-核心信息",
          permission: ["person"],
        },
      },
      {
        // 2.5 用户设置 - 补充信息 | 个人
        path: "basic-sttings/person/supplementary-information",
        name: "/userHome/basic-sttings/person/supplementary-information",
        component: () =>
          import(
            "../views/UserHome/BasicSettings/PersonInformation/SupInformationPerson.vue"
          ),
        meta: {
          title: "用户中心-设置-补充信息",
          permission: ["person"],
        },
      },
      {
        // 3.1 安全 - 首页 - 安全控制
        path: "safety/safety-control",
        name: "/userHome/safety/safety-control",
        component: () =>
          import("../views/UserHome/SafetyControl/SafetyControl.vue"),
        meta: {
          title: "用户中心-安全-安全管控",
          permission: ["person", "ins"],
        },
      },
      {
        // 3.2 安全 - 访问控制
        path: "safety/access-control",
        name: "/userHome/safety/access-control",
        component: () =>
          import("../views/UserHome/SafetyControl/AccessControl.vue"),
        meta: {
          title: "用户中心-安全-访问控制",
          permission: ["person", "ins"],
        },
      },
      {
        // 3.3 安全 - 欺诈预警
        path: "safety/fraud-warning",
        name: "/userHome/safety/fraud-warning",
        component: () =>
          import("../views/UserHome/SafetyControl/FraudWarning.vue"),
        meta: {
          title: "用户中心-安全-欺诈预警",
          permission: ["person", "ins"],
        },
      },
      {
        // 4.1 通知 - 通知中心
        path: "notifications/notifications-center/:readFlag",
        name: "/userHome/notifications/notifications-center",
        component: () =>
          import(
            "../views/UserHome/NotificationsCenter/NotificationsCenter.vue"
          ),
        meta: {
          title: "用户中心-通知-系统通知",
        },
      },
      {
        // 4.2 通知 - 基金公告
        path: "notifications/fund-announcement",
        name: "/userHome/notifications/fund-announcement",
        component: () =>
          import("../views/UserHome/NotificationsCenter/FundAnnouncement.vue"),
        meta: {
          title: "用户中心-通知-基金公告",
        },
      },
      {
        // 4.3 通知 - 持有人投票
        path: "notifications/online-voting",
        name: "/userHome/notifications/online-voting",
        component: () =>
          import("../views/UserHome/NotificationsCenter/OnlineVoting.vue"),
        meta: {
          title: "用户中心-通知-持有人投票",
        },
      },
      {
        // 10.1 持仓 - 首页
        path: "product-holdings",
        name: "/userHome/product-holdings",
        component: () =>
          import("../views/UserHome/ProductHoldings/ProductHoldings.vue"),
        meta: {
          title: "用户中心-持仓",
        },
      },
      {
        // 10.2 持仓 - 基金持仓
        path: "product-holdings/fund-subscription/:tradeType",
        name: "/userHome/product-holdings/fund-subscription",
        component: () =>
          import("../views/UserHome/ProductHoldings/FundSubscription.vue"),
        meta: {
          title: "用户中心-持仓-基金持仓",
        },
      },
      {
        // 5.1 个人 - 个人运营中心首页
        path: "personal-center",
        name: "/userHome/personal-center",
        component: () =>
          import("../views/UserHome/PersonalCenter/PersonalCenter.vue"),
        meta: {
          title: "用户中心-个人",
          permission: ["person"],
        },
      },
      {
        // 5.2.1 个人 - 个人运营中心 - 简历信息
        path: "personal-center/resume-information",
        name: "/userHome/personal-center/resume-information",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/ResumeInformation/ResumeInformation.vue"
          ),
        meta: {
          title: "用户中心-个人-简历信息",
          permission: ["person"],
        },
      },
      {
        // 5.2.2 个人 - 个人运营中心 - 在线填写01
        path: "personal-center/online-filling-first",
        name: "/userHome/personal-center/online-filling-first",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/ResumeInformation/OnlineFilling1.vue"
          ),
        meta: {
          title: "用户中心-个人-在线填写简历01",
          permission: ["person"],
        },
      },
      {
        // 5.2.3 个人 - 个人运营中心 - 在线填写02
        path: "personal-center/online-filling-second",
        name: "/userHome/personal-center/online-filling-second",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/ResumeInformation/OnlineFilling2.vue"
          ),
        meta: {
          title: "用户中心-个人-在线填写简历02",
          permission: ["person"],
        },
      },
      {
        // 5.2.3 个人 - 个人运营中心 - 在线填写03
        path: "personal-center/online-filling-third",
        name: "/userHome/personal-center/online-filling-third",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/ResumeInformation/OnlineFilling3.vue"
          ),
        meta: {
          title: "用户中心-个人-在线填写简历03",
          permission: ["person"],
        },
      },
      {
        // 5.3.1 个人 - 个人运营中心 - 胜任力测评
        path: "personal-center/competency-assessment",
        name: "/userHome/personal-center/competency-assessment",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CompetencyAssessment/CompetencyIndex.vue"
          ),
        meta: {
          title: "用户中心-个人-胜任力测评",
          permission: ["person"],
        },
      },
      {
        // 5.3.2 个人 - 个人运营中心 - 胜任力测评01
        path: "personal-center/competency-assessment-first",
        name: "/userHome/personal-center/competency-assessment-first",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CompetencyAssessment/CompetencyAssessment1.vue"
          ),
        meta: {
          title: "用户中心-个人-胜任力测评01",
          permission: ["person"],
        },
      },
      {
        // 5.3.2 个人 - 个人运营中心 - 胜任力测评02
        path: "personal-center/competency-assessment-second",
        name: "/userHome/personal-center/competency-assessment-second",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CompetencyAssessment/CompetencyAssessment2.vue"
          ),
        meta: {
          title: "用户中心-个人-胜任力测评02",
          permission: ["person"],
        },
      },
      {
        // 5.3.3.1 个人 - 个人运营中心 - 胜任力测评03|证券类
        path: "personal-center/competency-assessment-third/security",
        name: "/userHome/personal-center/competency-assessment-third/security",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CompetencyAssessment/CompetencyAssessment3_1.vue"
          ),
        meta: {
          title: "用户中心-个人-胜任力测评03",
          permission: ["person"],
        },
      },
      {
        // 5.3.3.2 个人 - 个人运营中心 - 胜任力测评03|股权类
        path: "personal-center/competency-assessment-third/shareholding",
        name: "/userHome/personal-center/competency-assessment-third/shareholding",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CompetencyAssessment/CompetencyAssessment3_2.vue"
          ),
        meta: {
          title: "用户中心-个人-胜任力测评03",
          permission: ["person"],
        },
      },
      {
        // 5.3.4 个人 - 个人运营中心 - 胜任力测评04
        path: "personal-center/competency-assessment-fourth",
        name: "/userHome/personal-center/competency-assessment-fourth",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CompetencyAssessment/CompetencyAssessment4.vue"
          ),
        meta: {
          title: "用户中心-个人-胜任力测评04",
          permission: ["person"],
        },
      },
      {
        // 5.3.5 个人 - 个人运营中心 - 胜任力测评05
        path: "personal-center/competency-assessment-fifth",
        name: "/userHome/personal-center/competency-assessment-fifth",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CompetencyAssessment/CompetencyAssessment5.vue"
          ),
        meta: {
          title: "用户中心-个人-胜任力测评05",
          permission: ["person"],
        },
      },
      {
        // 5.3.5 个人 - 个人运营中心 - 胜任力测评06
        path: "personal-center/competency-assessment-sixth",
        name: "/userHome/personal-center/competency-assessment-sixth",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CompetencyAssessment/Competency6.vue"
          ),
        meta: {
          title: "用户中心-个人-胜任力测评06",
          permission: ["person"],
        },
      },
      {
        // 5.3.5 个人 - 个人运营中心 - 胜任力测评07
        path: "personal-center/competency-assessment-seventh",
        name: "/userHome/personal-center/competency-assessment-seventh",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CompetencyAssessment/Competency7.vue"
          ),
        meta: {
          title: "用户中心-个人-胜任力测评07",
          permission: ["person"],
        },
      },
      {
        // 5.4.1 个人 - 个人运营中心 - 文件下载
        path: "personal-center/file-download",
        name: "/userHome/personal-center/file-download",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/FileDownload/FileDownload.vue"
          ),
        meta: {
          permission: ["person"],
        },
      },
      {
        // 5.5.1 个人 - 个人运营中心 - 我的收藏
        path: "personal-center/my-collection",
        name: "/userHome/personal-center/my-collection",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/MyCollection/MyCollection.vue"
          ),
        meta: {
          permission: ["person"],
        },
      },
      {
        // 5.6.1 个人 - 个人运营中心 - 点赞收藏
        path: "personal-center/like-follown",
        name: "/userHome/personal-center/like-follown",
        component: () =>
          import("../views/UserHome/PersonalCenter/LikeFollow/LikeFollow.vue"),
        meta: {
          permission: ["person"],
        },
      },
      {
        // 5.7.1 个人 - 个人运营中心 - 创建百科首页
        path: "personal-center/create-encyclopedia",
        name: "/userHome/personal-center/create-encyclopedia",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CreateEncyclopedia/CreateEncyclopedia.vue"
          ),
        meta: {
          permission: ["person"],
        },
      },
      {
        // 5.7.2 个人 - 个人运营中心 - 百科 - 募问
        path: "personal-center/encyclopedia-question",
        name: "/userHome/personal-center/encyclopedia-question",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CreateEncyclopedia/Question.vue"
          ),
        meta: {
          permission: ["person"],
        },
      },
      {
        // 5.7.3 个人 - 个人运营中心 - 百科 - 文件与下载
        path: "personal-center/encyclopedia-file-download",
        name: "/userHome/personal-center/encyclopedia-file-download",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CreateEncyclopedia/FileDownload.vue"
          ),
        meta: {
          permission: ["person"],
        },
      },
      {
        // 5.7.4 个人 - 个人运营中心 - 百科 - 基金词典
        path: "personal-center/encyclopedia-dictionary",
        name: "/userHome/personal-center/encyclopedia-dictionary",
        component: () =>
          import(
            "../views/UserHome/PersonalCenter/CreateEncyclopedia/Dictonary.vue"
          ),
        meta: {
          permission: ["person"],
        },
      },
      {
        // 5.8.1 个人 - 个人运营中心 - 提交工单
        path: "personal-center/work-order",
        name: "/userHome/personal-center/work-order",
        component: () =>
          import("../views/UserHome/PersonalCenter/WorkOrder/WorkOrder.vue"),
        meta: {
          permission: ["person"],
        },
      },
      {
        // 6.1 机构 - 机构运营中心首页
        path: "institution-center",
        name: "/userHome/institution-center",
        component: () =>
          import("../views/UserHome/InstitutionCenter/InstitutionCenter.vue"),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.2.1 机构 - 机构运营中心 - 机构百科首页
        path: "institution-center/encyclopedia",
        name: "/userHome/institution-center/encyclopedia",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/Encyclopedia/Encyclopedia.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.2.2 机构 - 机构运营中心 - 机构百科- 基本信息
        path: "institution-center/encyclopedia/encyclopedia-view",
        name: "/userHome/institution-center/encyclopedia/encyclopedia-view",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/Encyclopedia/EncyclopediaView.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.3.1 机构 - 机构运营中心 - 路演首页
        path: "institution-center/roadshow",
        name: "/userHome/institution-center/roadshow",
        component: () =>
          import("../views/UserHome/InstitutionCenter/Roadshow/Roadshow.vue"),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.3.2 机构 - 机构运营中心 - 路演 - 视频列表
        path: "institution-center/roadshow/video",
        name: "/userHome/institution-center/roadshow/video",
        component: () =>
          import("../views/UserHome/InstitutionCenter/Roadshow/VideoFile.vue"),
        meta: {
          title: "用户中心-路演视频",
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.3.2 机构 - 机构运营中心 - 路演 - 音频列表
        path: "institution-center/roadshow/audio",
        name: "/userHome/institution-center/roadshow/audio",
        component: () =>
          import("../views/UserHome/InstitutionCenter/Roadshow/AudioFile.vue"),
        meta: {
          title: "用户中心-路演音频",
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.3.2 机构 - 机构运营中心 - 路演 - 图文列表
        path: "institution-center/roadshow/img",
        name: "/userHome/institution-center/roadshow/img",
        component: () =>
          import("../views/UserHome/InstitutionCenter/Roadshow/ImgFile.vue"),
        meta: {
          title: "用户中心-路演图文",
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4 机构 - 机构运营中心 - 直营店首页
        path: "institution-center/sale-store",
        name: "/userHome/institution-center/sale-store",
        component: () =>
          import("../views/UserHome/InstitutionCenter/SaleStore/SaleStore.vue"),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.1 机构 - 机构运营中心 - 直营店-封面图文内容
        path: "institution-center/sale-store/cover",
        name: "/userHome/institution-center/sale-store/cover",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/Cover/Cover.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.2 机构 - 机构运营中心 - 直营店-产品信息管理
        path: "institution-center/sale-store/products",
        name: "/userHome/institution-center/sale-store/products",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/Products/Products.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.2.1 机构 - 机构运营中心 - 直营店 - 新增产品信息管理表单页1
        path: "institution-center/sale-store/products/information-management/first",
        name: "/userHome/institution-center/sale-store/products/information-management/first",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/Products/InformationManagement/InformationManagement1.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.2.2 机构 - 机构运营中心 - 直营店 - 新增产品信息管理表单页2
        path: "institution-center/sale-store/products/information-management/second",
        name: "/userHome/institution-center/sale-store/products/information-management/second",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/Products/InformationManagement/InformationManagement2.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.2.3 机构 - 机构运营中心 - 直营店 - 新增产品信息管理表单页3
        path: "institution-center/sale-store/products/information-management/third",
        name: "/userHome/institution-center/sale-store/products/information-management/third",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/Products/InformationManagement/InformationManagement3.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.3.1 机构 - 机构运营中心 - 直营店 - 产品数据管理
        path: "institution-center/sale-store/data-management",
        name: "/userHome/institution-center/sale-store/data-management",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/DataManagement/DataManagement.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.3.2 机构 - 机构运营中心 - 直营店 - 产品数据管理 | 股权类
        path: "institution-center/sale-store/data-management/shareholding",
        name: "/userHome/institution-center/sale-store/data-management/shareholding",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/DataManagementG/DataManagement.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.4 机构 - 机构运营中心 - 直营店 - 产品附件管理
        path: "institution-center/sale-store/annex-management",
        name: "/userHome/institution-center/sale-store/annex-management",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/AnnexManagement/AnnexManagement.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.5 机构 - 机构运营中心 - 直营店 - 基金研究分析(证券类)
        path: "institution-center/sale-store/research",
        name: "/userHome/institution-center/sale-store/research",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/Research/Research.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.6 机构 - 机构运营中心 - 直营店 - 基金研究分析(股权类)
        path: "institution-center/sale-store/research-equity",
        name: "/userHome/institution-center/sale-store/research-equity",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/Research/ResearchEquity.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.4.7 机构 - 机构运营中心 - 直营店 - 基金投资组合
        path: "institution-center/sale-store/investment-portfolio",
        name: "/userHome/institution-center/sale-store/investment-portfolio",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/SaleStore/InvestmentPortfolio/InvestmentPortfolio.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.7 机构 - 机构运营中心 - 信息披露首页
        path: "institution-center/information-disclosure",
        name: "/userHome/institution-center/information-disclosure",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/InformationDisclosure/InformationDisclosure.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.7.1 机构 - 机构运营中心 - 信息披露 - 客户管理 | 证券
        path: "institution-center/information-disclosure/customer-management",
        name: "/userHome/institution-center/information-disclosure/customer-management",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/InformationDisclosure/Customer/CustomerSecurity.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.7.1 机构 - 机构运营中心 - 信息披露 - 客户管理 | 股权
        path: "institution-center/information-disclosure/customer-management/equity",
        name: "/userHome/institution-center/information-disclosure/customer-management/equity",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/InformationDisclosure/Customer/CustomerEquity.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.7.2 机构 - 机构运营中心 - 信息披露 - 持有人绑定
        path: "institution-center/information-disclosure/basic-information",
        name: "/userHome/institution-center/information-disclosure/basic-information",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/InformationDisclosure/BasicInformation/BasicInformationIndex.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.7.3 机构 - 机构运营中心 - 信息披露 - 基金公告
        path: "institution-center/information-disclosure/fund-announcement",
        name: "/userHome/institution-center/information-disclosure/fund-announcement",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/InformationDisclosure/FundAnnouncement/FundAnnouncement.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.7.4 机构 - 机构运营中心 - 信息披露 - 附属账户
        path: "institution-center/information-disclosure/affiliated-account",
        name: "/userHome/institution-center/information-disclosure/affiliated-account",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/InformationDisclosure/AffiliatedAccount/AffiliatedAccount.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.7.5 机构 - 机构运营中心 - 信息披露 - 用户监测
        path: "institution-center/information-disclosure/user-monitoring",
        name: "/userHome/institution-center/information-disclosure/user-monitoring",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/InformationDisclosure/UserMonitoring/UserMonitoring.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.7.6 机构 - 机构运营中心 - 信息披露 - 持有人会议
        path: "institution-center/information-disclosure/online-voting",
        name: "/userHome/institution-center/information-disclosure/online-voting",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/InformationDisclosure/OnlineVoting/OnlineVoting.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },
      {
        // 6.7.7 机构 - 机构运营中心 - 信息披露 - 批量注册
        path: "institution-center/information-disclosure/batch-import",
        name: "/userHome/institution-center/information-disclosure/batch-import",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/InformationDisclosure/BatchImport/BatchImport.vue"
          ),
        meta: {
          permission: ["ins", "ins_card"],
        },
      },

      {
        // 6.5.1 机构 - 机构运营中心 - 我的收藏--------------------------------------------------------------
        path: "institution-center/my-collection",
        name: "/userHome/institution-center/my-collection",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/MyCollection/MyCollection.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.5.2 机构 - 机构运营中心 - 点赞关注
        path: "institution-center/like-follown",
        name: "/userHome/institution-center/like-follown",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/LikeFollow/LikeFollow.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.5.3 机构 - 机构运营中心 - 创建百科首页
        path: "institution-center/create-encyclopedia",
        name: "/userHome/institution-center/create-encyclopedia",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/CreateEncyclopedia/CreateEncyclopedia.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.6.1 机构 - 机构运营中心 - 百科 - 募问
        path: "institution-center/encyclopedia-question",
        name: "/userHome/institution-center/encyclopedia-question",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/CreateEncyclopedia/Question.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.6.2 机构 - 机构运营中心 - 百科 - 文件与下载
        path: "institution-center/encyclopedia-file-download",
        name: "/userHome/institution-center/encyclopedia-file-download",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/CreateEncyclopedia/FileDownload.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.6.3 机构 - 机构运营中心 - 百科 - 基金词典
        path: "institution-center/encyclopedia-dictionary",
        name: "/userHome/institution-center/encyclopedia-dictionary",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/CreateEncyclopedia/Dictonary.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.7.1 机构 - 机构运营中心 - 提交工单
        path: "institution-center/work-order",
        name: "/userHome/institution-center/work-order",
        component: () =>
          import("../views/UserHome/InstitutionCenter/WorkOrder/WorkOrder.vue"),
        meta: {
          permission: ["ins"],
        },
      },
      {
        // 6.8.1 机构 - 机构运营中心 - 奖项排名首页
        path: "institution-center/awards-ranking",
        name: "/userHome/institution-center/awards-ranking",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/AwardsRanking/AwardsRanking.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },

      {
        // 6.8.1 机构 - 机构运营中心 - 奖项排名首页
        path: "institution-center/awards-fund-selection",
        name: "/userHome/institution-center/awards-fund-selection",
        component: () =>
          import(
            "../views/UserHome/InstitutionCenter/AwardsRanking/FundSelection.vue"
          ),
        meta: {
          permission: ["ins"],
        },
      },

      {
        // 7.1.1 工具 - 常用工具 - 首页
        path: "tools",
        name: "/userHome/tools",
        component: () => import("../views/UserHome/Tools/Tools.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 7.1.2 工具 - 常用工具 - 购物车
        path: "tools/shopping-cart",
        name: "/userHome/tools/shopping-cart",
        component: () => import("../views/UserHome/Tools/ShoppingCart.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 7.1.3 工具 - 常用工具 - 我的订单
        path: "tools/my-order",
        name: "/userHome/tools/my-order",
        component: () => import("../views/UserHome/Tools/MyOrder.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 7.1.4 工具 - 常用工具 - 申请合同
        path: "tools/applying-contract",
        name: "/userHome/tools/applying-contract",
        component: () => import("../views/UserHome/Tools/ApplyingContract.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 7.1.4 工具 - 常用工具 - 申请合同(单个详情)
        path: "tools/applying-contract-detail/:outTradeNo",
        name: "/userHome/tools/applying-contract-detail",
        component: () =>
          import("../views/UserHome/Tools/ApplyingContractDetail.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 7.1.5 工具 - 常用工具 - 发票相关
        path: "tools/invoice",
        name: "/userHome/tools/invoice",
        component: () => import("../views/UserHome/Tools/Invoice.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 7.1.5 工具 - 常用工具 - 发票相关(单个详情)
        path: "tools/invoice-detail/:outTradeNo",
        name: "/userHome/tools/invoice-detail",
        component: () => import("../views/UserHome/Tools/InvoiceDetail.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 7.1.6 工具 - 常用工具 - 邮寄地址
        path: "tools/mailing-address",
        name: "/userHome/tools/mailing-address",
        component: () => import("../views/UserHome/Tools/MailingAddress.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 7.1.7 工具 - 常用工具 - 积分兑换
        path: "tools/redemption-points",
        name: "/userHome/tools/redemption-points",
        component: () => import("../views/UserHome/Tools/RedemptionPoints.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 8.1.1 电子卡 - 首页
        path: "electronic-card/:type",
        name: "/userHome/electronic-card",
        component: () =>
          import("../views/UserHome/ElectronicCard/ElectronicCard.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 8.2.1 电子卡 - 购买步骤1
        path: "electronic-card/BuyStep-one/:id",
        name: "/userHome/electronic-card/BuyStep-one",
        component: () =>
          import("../views/UserHome/ElectronicCard/BuyStep1.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 8.2.2 电子卡 - 购买步骤2
        path: "electronic-card/BuyStep-two/:id",
        name: "/userHome/electronic-card/BuyStep-two",
        component: () =>
          import("../views/UserHome/ElectronicCard/BuyStep2.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 8.2.3 电子卡 - 购买步骤3
        path: "electronic-card/BuyStep-three/:id",
        name: "/userHome/electronic-card/BuyStep-three",
        component: () =>
          import("../views/UserHome/ElectronicCard/BuyStep3.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
      {
        // 9.1.1 特定对象 - 首页
        path: "specific-objects/:open",
        name: "/userHome/specific-objects",
        component: () =>
          import("../views/UserHome/SpecificObjects/SpecificObjects.vue"),
        meta: {
          permission: ["person", "ins"],
        },
      },
    ],
  },
  {
    path: "/userHome/", // 用户中心主页
    redirect: "/userHome/index",
    meta: {
      permission: ["person", "ins"],
    },
  },

  {
    path: "/bottom", // 底部-法律条款
    component: () => import("../views/BottomNavigation/index"),
    children: [
      {
        path: "/bottom/termsForUsage", // 使用条款
        component: () =>
          import("../views/BottomNavigation/termsForUsage/termsForUsage"),
      },
      {
        path: "/bottom/cookies-page", // 管理cookie
        component: () =>
          import("../views/BottomNavigation/CookiessPage/CookiessPage"),
      },
      {
        path: "/bottom/privacy-policy", // 隐私政策
        component: () =>
          import("../views/BottomNavigation/PrivacyPolicy/PrivacyPolicy"),
      },
    ],
  },
  {
    // 机构百科 + 机构详情
    path: "/home/Encyclopedia/page",
    name: "/home/Encyclopedia/page",
    component: () => import("../views/Encyclopedia/EncyclopediaHomeView.vue"),
    children: [
      {
        path: "institutional/:insName/:createUserId/:id", // 2.1 机构-机构百科
        name: "/home/Encyclopedia/page/institutional",
        component: () => import("../views/Encyclopedia/InstitutionalNew.vue"),
      },
      {
        path: "deta/:insName/:createUserId/:id", // 2.1.1 机构-详情
        name: "/home/Encyclopedia/page/deta",
        component: () => import("../views/Encyclopedia/EncycDeta.vue"),
      },
    ],
  },
  {
    // 6.1 直营店产品详情页
    path: "/home/SaleStore/page", // 6.直营店
    name: "/home/SaleStore/page",
    component: () =>
      import("../views/SaleStore/components/SaleStoreHomeView.vue"),
    children: [
      {
        path: "SaleStore-deta/:insName/:createUserId", // 7.1 直营店详情页
        name: "/home/SaleStore/page/SaleStore-deta",
        component: () => import("../views/SaleStore/SaleStoreDetails.vue"),
      },
      {
        path: "SaleStore-product/:insName/:createUserId/:directId", // 7.1.1 直营店产品详情页
        name: "/home/SaleStore/page/SaleStore-product",
        component: () => import("../views/SaleStore/SaleStoreProduct.vue"),
      },
    ],
  },
  {
    // 7.1 路演详情页
    path: "/home/Roadshow/page",
    name: "/home/Roadshow/page",
    component: () =>
      import("../views/Roadshow/components/RoadshowHomeView.vue"),
    children: [
      {
        path: "Roadshow-deta/:managerName/:id", // 7.1 路演详情页
        name: "/home/Roadshow/page/Roadshow-deta",
        component: () => import("../views/Roadshow/RoadshowDetails.vue"),
      },
    ],
  },
  {
    // 8.1 关于我们
    path: "/home/AboutUs",
    redirect: "/home/AboutUs/index",
    // hidden: true,
  },
  {
    // 8.1 关于我们
    path: "/home/AboutUs", // 8.1 关于我们
    name: "/home/AboutUs",
    component: () => import("../views/AboutUs/AboutUsView.vue"),
    children: [
      {
        path: "index", // 8.1 关于我们首页
        name: "/home/AboutUs/index",
        component: () => import("../views/AboutUs/AboutUs.vue"),
        meta: {
          isLogin: false,
          title: "关于我们",
        },
      },
      {
        path: "PrivateButler", // 8.2 私募管家网
        name: "/home/AboutUs/PrivateButler",
        component: () => import("../views/AboutUs/PrivateButler.vue"),
        meta: {
          isLogin: false,
          title: "私募管家网",
        },
      },
      {
        path: "CareerPlanning", // 8.3 职业规划
        name: "/home/AboutUs/CareerPlanning",
        component: () => import("../views/AboutUs/CareerPlanning.vue"),
        meta: {
          isLogin: false,
          title: "职业规划",
        },
      },
      {
        path: "ProductsServices", // 8.4 产品与服务
        name: "/home/AboutUs/ProductsServices",
        component: () => import("../views/AboutUs/ProductsServices.vue"),
        meta: {
          isLogin: false,
          title: "产品与服务",
        },
      },
      {
        path: "InformationDisclosure", // 8.5 信息披露
        name: "/home/AboutUs/InformationDisclosure",
        component: () => import("../views/AboutUs/InformationDisclosure.vue"),
        meta: {
          isLogin: false,
          title: "信息披露",
        },
      },
      {
        path: "FollowUs", // 8.6 跟随我们
        name: "/home/AboutUs/FollowUs",
        component: () => import("../views/AboutUs/FollowUs.vue"),
        meta: {
          isLogin: false,
          title: "跟随我们",
        },
      },
    ],
  },
  {
    // 9.1 用户中心 - 实名认证|步骤页
    path: "/userHome/step",
    name: "/userHome/step",
    component: () => import("../views/UserHome/StepHome/StepHome.vue"),
    children: [
      {
        // 9.1.1 用户中心 - 设置 - 个人实名认证
        path: "real-name/person",
        name: "/userHome/step/real-name/person",
        component: () =>
          import("../views/UserHome/BasicSettings/RealName/Person.vue"),
        meta: {
          title: "用户中心 - 个人实名认证",
          permission: ["person"],
        },
      },
      {
        // 9.1.2 用户中心 - 设置 - 企业实名认证
        path: "real-name/company",
        name: "/userHome/step/real-name/company",
        component: () =>
          import("../views/UserHome/BasicSettings/RealName/Company.vue"),
        meta: {
          title: "用户中心 - 企业实名认证",
          permission: ["ins"],
        },
      },
      {
        // 9.2.1 用户中心 - 特定对象 - 基本信息表 - 个人
        path: "specific-objects/basic-information",
        name: "/userHome/step/specific-objects/basic-information",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPages/BasicInformationPage.vue"
          ),
        meta: {
          title: "用户中心 - 基本信息表",
          permission: ["person", "ins"],
        },
      },
      {
        // 9.2.1 用户中心 - 特定对象 - 基本信息表 - 企业
        path: "specific-objects/basic-information-cop",
        name: "/userHome/step/specific-objects/basic-information-cop",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPagesCom/BasicInformationPage.vue"
          ),
        meta: {
          title: "用户中心 - 基本信息表",
          permission: ["ins"],
        },
      },
      {
        // 9.3.1 用户中心 - 特定对象 - 税收居民身份 - 个人
        path: "specific-objects/tax-resident",
        name: "/userHome/step/specific-objects/tax-resident",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPages/TaxResident.vue"
          ),
        meta: {
          title: "用户中心 - 税收居民身份",
          permission: ["person", "ins"],
        },
      },
      {
        // 9.3.1 用户中心 - 特定对象 - 税收居民身份 - 企业
        path: "specific-objects/tax-resident-cop",
        name: "/userHome/step/specific-objects/tax-resident-cop",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPagesCom/TaxResident.vue"
          ),
        meta: {
          title: "用户中心 - 税收居民身份",
          permission: ["ins"],
        },
      },
      {
        // 9.4.1 用户中心 - 特定对象 - 资产证明 - 个人
        path: "specific-objects/asset-proof",
        name: "/userHome/step/specific-objects/asset-proof",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPages/AssetProof.vue"
          ),
        meta: {
          title: "用户中心 - 资产证明",
          permission: ["person", "ins"],
        },
      },
      {
        // 9.4.1 用户中心 - 特定对象 - 资产证明 - 企业
        path: "specific-objects/asset-proof-cop",
        name: "/userHome/step/specific-objects/asset-proof-cop",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPagesCom/AssetProof.vue"
          ),
        meta: {
          title: "用户中心 - 资产证明",
          permission: ["ins"],
        },
      },
      {
        // 9.5.1 用户中心 - 特定对象 - 风险测评 | 个人
        path: "specific-objects/risk-assessment-p",
        name: "/userHome/step/specific-objects/risk-assessment-p",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPages/RiskAssessmentPerson.vue"
          ),
        meta: {
          title: "用户中心 - 风险测评",
          permission: ["person", "ins"],
        },
      },
      {
        // 9.5.2 用户中心 - 特定对象 - 风险测评 | 企业
        path: "specific-objects/risk-assessment-cop",
        name: "/userHome/step/specific-objects/risk-assessment-cop",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPages/RiskAssessmentCompany.vue"
          ),
        meta: {
          title: "用户中心 - 风险测评",
          permission: ["ins"],
        },
      },
      {
        // 9.6.1 用户中心 - 特定对象 - 合格投资者承诺 - 个人
        path: "specific-objects/commitment",
        name: "/userHome/step/specific-objects/commitment",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPages/Commitment.vue"
          ),
        meta: {
          title: "用户中心 - 合格投资者承诺",
          permission: ["person", "ins"],
        },
      },
      {
        // 9.6.1 用户中心 - 特定对象 - 合格投资者承诺 - 企业
        path: "specific-objects/commitment-cop",
        name: "/userHome/step/specific-objects/commitment-cop",
        component: () =>
          import(
            "../views/UserHome/SpecificObjects/SpecificObjectsPagesCom/Commitment.vue"
          ),
        meta: {
          title: "用户中心 - 合格投资者承诺",
          permission: ["ins"],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 在路由切换前执行滚动条回到顶部的操作
    return { left: 0, top: 0 };
  },
});

// 路由前置守卫
let lastUrl = "";
router.beforeEach((to, from, next) => {
  // 防止无限循环
  if (lastUrl != from) {
    lastUrl = from;
    permission(to, from, next);
  } else {
    next();
  }
});

// 路由后置守卫
router.afterEach((to, from) => {
  document.title = to.meta.title || process.env.VUE_APP_title; // 修改网页的title
});

export default router;
